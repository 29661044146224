import React from "react";
import ReactPlayer from "react-player";
import {PauseCircle, PlayCircle} from "react-feather";
import {Button} from "reactstrap";
import classnames from "classnames";
import {init_mini_player} from "../../App";

const logo_standard = require("../../assets/img/logo/uzakrota-logo-dark.png")

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.iframeRef = props.iframeRef || React.createRef()
    this.playHandle = this.playHandle.bind(this)
    this.setStateUrl = this.setStateUrl.bind(this)
  }

  state = {
    playing: false,
    stateStreamUrl: ""
  }

  playHandle = () => {
    this.setState({
      playing: !this.state.playing
    })
  }

  startPlay = () => {
    this.setState({
      playing: true
    })
  }

  stopPlay = () => {
    this.setState({
      playing: false
    })
  }

  componentDidMount() {
    if (typeof this.props.setPlayerRef === "function")
      this.props.setPlayerRef(this)

    let streamUrl = ""
    let {stateStreamUrl} = this.state
    if (typeof stateStreamUrl === "string" && stateStreamUrl)
      streamUrl = stateStreamUrl;

    let {mini} = this.props

    if (typeof this.props.streamUrl == "string" && this.props.streamUrl)
      streamUrl = this.props.streamUrl

    if (!mini)
      this.setMiniPlayer(streamUrl, this.props.stages || [])
  }


  setStateUrl = (url, cb) => {
    this.setState({stateStreamUrl: url}, () => {
      if (typeof cb === "function")
        cb()
    })
  }

  setLocalStorage = (url) => {
    if (typeof url === "string" && url)
      localStorage.setItem("active_stream_url", url)
  }

  setMiniPlayer = (url, stages) => {
    init_mini_player(url, stages)
  }

  render() {
    let {streamUrl, autoplay, width, height, mini, after, extraControls} = this.props
    let {playing, stateStreamUrl} = this.state
    let self = this

    return <div className={classnames("react-custom-player", {
      "not-playing": !playing
    })}><ReactPlayer
      ref={this.iframeRef}
      style={{margin: "0 auto", padding: 5}}
      className={"shadow bg-primary rounded no-border-bottom-radius"}
      width={width}
      height={height}
      playing={playing}
      wrapper={"div"}
      onPlay={() => {
        this.setState({
          playing: true
        })
      }}
      onPause={() => {
        this.setState({
          playing: !true
        })
      }}
      url={stateStreamUrl || streamUrl}
      {...this.props}
      onReady={() => {
        if (typeof this.props.onReady === "function") {
          this.props.onReady(self)
        }
      }}

    />

      {<Button size={" sm"} color={playing ? " white" : " dark"} onClick={this.playHandle}
               className={classnames(" btn btn-sm play-pause-button p-0 rounded-circle", {
                 " btn-primary": !playing,
                 " btn-light": playing
               })}>
        {this.state.playing ? <><PauseCircle size={60}/></> : <><PlayCircle size={60}/></>}
      </Button>}

      <div className={classnames("bg-primary text-left rounded no-border-top-radius", {
        "d-none": mini,
        "d-flex": !mini
      })}
           style={{width: (width - 0), margin: "0 auto 4px auto", padding: 5}}
      >
        <div className={"controls w-100 text-center"}>
          <div>
            <div className={" text-center"} style={{paddingBottom: 5}}>
              <img src={logo_standard} height={15}/>
            </div>
            {extraControls}
          </div>
        </div>
        {after}
      </div>
    </div>;
  }
}

export default VideoPlayer;
