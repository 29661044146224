import React from "react";
import {fireAuth, fireReal} from "../authServices/firebase/firebaseConfig";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import {toast} from "react-toastify";
import moment from "moment";
import {frontendBaseUrl} from "../App";

class GlobalNotifications extends React.Component {
  refName = "notifications"
  fireQueries = []

  state = {
    modalOpen: false,
    modalTitle: "",
    modalContent: ""
  }

  processNotify = (data, target) => {
    let self = this
    let _type = data.type
    switch (_type) {
      case "modal":
        self.setState({
          modalOpen: true,
          modalTitle: data.title || "",
          modalContent: data.content || "",
        })
        break;
      case 'marquee':
        break;
      default:
        let options = {
          position: data.position || "top-right",
          autoClose: false,
          closeOnClick: true,
          onClose: function () {
            if (target !== "all") {
              fireReal.ref(self.refName + '/' + target + '/' + data.key).update({isRead: true})
                .then((result) => {
                })
            }
          }
        };
        let theme = data.theme
        let message = ""
        if (data.data.type === "meeting") {
          message = <FormattedHTMLMessage id={"notify.meetingMessage"} values={{
            time: moment(data.data.time / 1000).format("HH:mm"),
            name: data.data.staff_name || "",
            join_url: `${frontendBaseUrl}/expo-meeting/${data.data.meeting_id}`
          }}/>
        } else if (data.data.type === "program") {
          message = <FormattedHTMLMessage id={"notify.programMessage"} values={{
            time: moment(data.data.time / 1000).format("HH:mm"),
            program_name: data.data.name || "",
            join_url: `${frontendBaseUrl}/stage/${data.data.stage_id}`
          }}/>
        } else {
          message = typeof data.data === "object" ? data.data.content : ""
          if(message==="")
            message = typeof data.content === "string" ? data.content : ""
          message = <div dangerouslySetInnerHTML={{__html: message || ""}}/>
        }

        switch (theme) {
          default:
            toast.info(message, options)
            break;
          case "success":
            toast.success(message, options)
            break;
          case "warning":
            toast.warn(message, options)
            break;
          case "error":
            toast.error(message, options)
            break;
          case "default":
            toast(message, options)
            break;
        }
        break;
    }

    return ""
  }

  listenNotify = (target) => {
    let self = this

    let Query = fireReal.ref(this.refName + '/' + target)
      .orderByChild('createdAt')
      .startAt(Date.now())
    this.fireQueries.push(Query)
    Query.on('child_added', function (item) {
      let data = item.val()
      data.key = item.key
      self.processNotify(data, target)
    });

    return []
  }

  offListeners = () => {
    this.fireQueries.forEach(function (l) {
      l.off("child_added")
    })
  }

  constructor(props) {
    super(props);
    let self = this
    fireAuth().onAuthStateChanged(function (user) {
      if (user) {
        self.listenNotify("all")
        self.listenNotify(localStorage.getItem("userUid") || fireAuth().currentUser.uid)
        self.props.reply()
      }
    })
  }

  render() {
    return <div>
      <Modal isOpen={this.state.modalOpen}>
        <ModalHeader>{this.state.modalTitle || ""}</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{__html: this.state.modalContent || ""}}/>
        </ModalBody>
        <ModalFooter className={"text-right"}>
          <Button onClick={() => {
            this.setState({
              modalOpen: false,
              modalTitle: "",
              modalContent: ""
            })
          }} color={"primary"}>
            <FormattedMessage id={"globalModal.close"}/>
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  }
}

export default GlobalNotifications
