import GlobalNavigationConfig from "./navigationConfig-global";


const navigationConfigTr = GlobalNavigationConfig({
  lobby: "Lobi",
  buyTicket: "Bilet Al",
  buyStand: 'Stand Al',
  stage: "Sahne",
  agenda: "Program Akışı",
  expo: "Fuar",
  social: "Sosyal",
  adminPanel: "Sistem Yönetimi",
  speakerPanel: "Konuşmacı Alanı",
  standPanel: "Stand Yönetimi",
  staffPanel: "Stand Temsilciliği"
})

export default navigationConfigTr
