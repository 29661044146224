import * as firebase from "firebase";

export const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}
if (!firebase.apps.length) {
  firebase.initializeApp(config)
  firebase.analytics()
}
export const fireDb = firebase.firestore();
export const fireAuth = firebase.auth
export const fireStorage = firebase.storage().ref()
export const fireReal = firebase.database()
export const firePerf = firebase.performance()

export const isAdmin = () => {
  let roles = localStorage.getItem("userRole")
  if (typeof roles !== "string" || roles.length === 0)
    roles = '["isVisitor"]'
  roles = JSON.parse(roles)
  return roles.indexOf("isAdmin") > -1
}
