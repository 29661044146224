import React from "react"
import {Navbar} from "reactstrap"
import classnames from "classnames"
import NavbarUser from "./NavbarUser"
import CountDown from "./CountDown";
import FlashNotification from "./FlashNotification";
import Talk from "talkjs";
import {frontendBaseUrl, getCurrentUserLang} from "../../../App";
import talkJSConfig from "../../../configs/talkJsConfig";
import {toast} from "react-toastify";
import {FormattedHTMLMessage} from "react-intl";
import {fireAuth, fireDb} from "../../../authServices/firebase/firebaseConfig";
import {history} from "../../../history";
import moment from "moment-timezone";
import {FaHamburger, FiMenu, GiHamburgerMenu} from "react-icons/all";

const isLocal = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")

class ThemeNavbar extends React.Component {
  state = {
    unreadMessage: 0
  }

  interval = null
  sessionFailCount = 0

  constructor(props) {
    super(props);
    this.flashRef = React.createRef()
    this.sessionHeartBeat()
    this.timezoneFromProfile()
  }

  timezoneFromProfile = () => {
    if (typeof localStorage.getItem("timezone") === "string" && localStorage.getItem("timezone") !== "")
      return ""

    let user_id = localStorage.getItem("userUid")
    if (!user_id)
      user_id = (fireAuth().currentUser !== null && typeof fireAuth().currentUser.uid === "string" && fireAuth().currentUser.uid)

    if (!user_id)
      return ""

    const loadTimezoneIfNotExists = () => {
      let userTZ = moment.tz.guess();
      localStorage.setItem("timezone", userTZ)
      fireDb.collection("user").doc(user_id).update({timezone: userTZ})
        .then(result => {
        }).catch()
    }


    if (user_id) {
      fireDb.collection("user").doc(user_id).get().then(item => {
        if (item) {
          let user_data = item.data()
          if (typeof user_data !== "undefined" && typeof user_data.timezone === "string" && user_data.timezone !== "") {
            localStorage.setItem("timezone", user_data.timezone)
          } else {
            loadTimezoneIfNotExists()
          }
        } else {
          loadTimezoneIfNotExists()
        }
      })
    }
  }

  sessionHeartBeat = () => {
    let self = this
    this.interval = setInterval(function () {
      if (fireAuth().currentUser === null) {
        self.sessionFailCount++
        if (self.sessionFailCount > 2) {
          fireAuth().signOut().then(function () {
            // localStorage.clear()
            localStorage.removeItem("isLoggedIn")
            localStorage.removeItem("userAvatar")
            localStorage.removeItem("userEmail")
            localStorage.removeItem("userName")
            localStorage.removeItem("userUid")
            localStorage.removeItem("userTalkSignature")
            localStorage.setItem("userRole", '["isVisitor"]')
            clearInterval(self.interval)
            self.sessionFailCount = 0
            history.push("/pages/login")
          })
        }
      } else {
        self.sessionFailCount = 0
        clearInterval(self.interval)
      }
    }, 3 * 1000);
  }

  componentDidMount() {
    Talk.ready.then(() => {
      let me = new Talk.User({
        role: "General",
        id: localStorage.getItem("userUid"),
        name: localStorage.getItem("userName"),
        photoUrl: localStorage.getItem("userAvatar"),
        locale: getCurrentUserLang() === "tr" ? "tr-TR" : "en-US"
      });
      let talkSession = new Talk.Session({
        appId: talkJSConfig.appId,
        me: me,
        signature: localStorage.getItem("userTalkSignature") || ''
      });

      // if(isLocal) {
      talkSession.unreads.on("change", unreadConversations => {
        let oldCount = this.state.unreadMessage
        this.setState({unreadMessage: unreadConversations.length})
        if (oldCount)
          return false;

        if (typeof unreadConversations === "object" && unreadConversations.length) {
          let content = "";
          if (unreadConversations.length > 1) {
            content = <FormattedHTMLMessage id={"main.chatNotifyMultiple"} values={{
              message_counter: unreadConversations.length,
              url: frontendBaseUrl + '/pm'
            }}/>
          } else {
            let sender_name = ""
            if (typeof unreadConversations[0] === "object" && typeof unreadConversations[0].lastMessage === "object")
              sender_name = unreadConversations[0].lastMessage.sender.name
            content = <FormattedHTMLMessage id={"main.chatNotifySingle"} values={{
              sender_name: sender_name,
              url: `${frontendBaseUrl}/pm`
            }}/>
          }

          // content = <div dangerouslySetInnerHTML={{__html: content}}/>

          toast.info(content, {
            autoClose: 5 * 1000,
            position: "bottom-right"
          })
        }
      });
      // }
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    let props = this.props
    const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"]
    const navbarTypes = ["floating", "static", "sticky", "hidden"]
    return (
      <React.Fragment>
        <div className="content-overlay"/>
        <div className="bg-transparent"/>
        <Navbar
          className={classnames(
            "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
            {
              "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
              "navbar-dark": colorsArr.includes(props.navbarColor),
              "bg-primary":
                props.navbarColor === "primary" && props.navbarType !== "static",
              "bg-danger":
                props.navbarColor === "danger" && props.navbarType !== "static",
              "bg-success":
                props.navbarColor === "success" && props.navbarType !== "static",
              "bg-info":
                props.navbarColor === "info" && props.navbarType !== "static",
              "bg-warning":
                props.navbarColor === "warning" && props.navbarType !== "static",
              "bg-dark":
                props.navbarColor === "dark" && props.navbarType !== "static",
              "d-none": props.navbarType === "hidden" && !props.horizontal,
              "floating-nav":
                (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
              "navbar-static-top":
                props.navbarType === "static" && !props.horizontal,
              "fixed-top": props.navbarType === "sticky" || props.horizontal,
              "scrolling": props.horizontal && props.scrolling

            }
          )}
        >
          <div className="navbar-wrapper">
            <div className="navbar-container content header-content">
              <div
                className="navbar-collapse d-flex justify-content-between align-items-center"
                id="navbar-mobile"
              >
                <div className={classnames("cursor-pointer", {
                  "d-none": window.innerWidth >= 1200
                })} style={{padding: "0 10px"}} onClick={() => {
                  if (typeof this.props.menuHandler === "function")
                    this.props.menuHandler()
                }}>
                  <FiMenu size={21}/>
                </div>
                {window.innerWidth >= 1200 && <FlashNotification ref={this.flashRef} reply={this.props.reply}/>}

                {props.horizontal ? (
                  <div className="logo d-flex align-items-center">
                    <div className="brand-logo mr-50"></div>
                    <h2 className="text-primary brand-text mb-0">Uzakrota</h2>
                  </div>
                ) : null}
                <NavbarUser
                  unreadMessage={this.state.unreadMessage}
                  handleAppOverlay={props.handleAppOverlay}
                  changeCurrentLang={props.changeCurrentLang}
                  userName={localStorage.userName || 'Kullanıcı'}
                  userImg={""}
                />
              </div>
            </div>
          </div>
        </Navbar>
        {window.innerWidth < 1200 && <FlashNotification ref={this.flashRef} reply={this.props.reply}/>}
      </React.Fragment>
    )
  }
}


export default ThemeNavbar
