import React from "react"
import Draggable from "react-draggable";

import "react-resizable/css/styles.css";
import {AiOutlineCloseCircle, BiMoveHorizontal, BiTv, GrChannel, IoIosOptions} from "react-icons/all";
import VideoPlayer from "../Gri/VideoPlayer";
import {Input} from "reactstrap";
import {FormattedMessage} from "react-intl";


class MiniPlayer extends React.Component {

  iframeRef = React.createRef()
  playerRef = null
  playerContainer = React.createRef()

  constructor(props) {
    super(props);
    if (window.addEventListener) {  // all browsers except IE before version 9
      window.addEventListener("message", this.OnMessage, false);
    } else {
      if (window.attachEvent) {   // IE before version 9
        window.attachEvent("onmessage", this.OnMessage);
      }
    }
  }

  state = {
    stages: [],
    selectedStage: null
  }

  OnMessage = (e) => {
    let {data} = e
    let self = this
    if (this.playerContainer.current === null)
      return false

    let _data = data.data
    if (typeof _data !== "object")
      _data = {}

    switch (data.event) {
      default:
        break;

      case 'mini_player_init':
        this.playerRef.setStateUrl(_data.streamUrl)
        this.setState({stages: _data.stages})
        break;

      case 'mini_player_show':
        if (typeof this.playerRef === "object" && this.playerRef !== null) {
          // self.playerRef.startPlay()
        }
        this.playerContainer.current.classList.add('show')
        break;

      case 'mini_player_hide':
        if (typeof this.playerRef === "object" && this.playerRef !== null) {
          self.playerRef.stopPlay()
        }
        this.playerContainer.current.classList.remove('show')
        break;
    }
  }

  render() {
    return <div className={"custom-mini-player-container"} ref={this.playerContainer}>
      <Draggable axis={"x"} handle=".handle" bounds={"parent"}>
        <div className="custom-mini-player rounded shadow border bg-white mr-1 ml-1 mb-1" style={{height: 300}}>
          <VideoPlayer
            width={450} height={300}
            iframeRef={this.iframeRef}
            setPlayerRef={(pl) => {
              this.playerRef = pl
            }}
            mini={true}
            onError={() => {
            }}
            autoplay={false}
            onPlay={() => {
            }}
            onPause={() => {
            }}
          />

          <div className="drag-navigation">
            {(typeof this.state.stages && this.state.stages.length) &&
            <div className={"d-inline-block bg-primary text-white float-left stage-selector-div"}>
              <GrChannel className={"float-left"} color={"#fff"} style={{marginTop: 2, marginRight: 5}}/>
              <FormattedMessage id={"miniPlayer.stageSelector"} tagName={"span"}/>
              <Input className={"stage-selector"} type={"select"} onChange={(e) => {
                this.playerRef.setStateUrl(e.target.value)
              }}>
                {this.state.stages.map(item => <option value={item.value}>{item.name}</option>)}
              </Input>
            </div>}
            <div className="d-inline-block button-item float-right ml-2">
              <span className={"handle cursor-pointer"}><BiMoveHorizontal size={24}/></span>
            </div>
            <div
              className="float-left close d-flex justify-content-center button-item cursor-pointer ml-1"
              onClick={() => {
                window.postMessage({
                  event: "mini_player_hide"
                }, "*");
              }}
            >
              <AiOutlineCloseCircle size={24}/>
            </div>
          </div>

        </div>
      </Draggable>
    </div>
      ;
  }
}

export default MiniPlayer
