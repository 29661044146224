import React from "react"
import {fireDb} from "../../../authServices/firebase/firebaseConfig";
import Countdown from "react-countdown-now";
import {FormattedMessage} from "react-intl";
import {GiAlarmClock} from "react-icons/all";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import {activeEventId} from "../../../App";

function RenderTimer({days, hours, minutes, seconds}) {
  return (
    <React.Fragment>

      <UncontrolledTooltip target={"clockIcon"}>
        <FormattedMessage id={"navbar.eventClock"} />
      </UncontrolledTooltip>

      <div className="clockCard text-center px-1">
        <GiAlarmClock id={"clockIcon"} size={36} />
      </div>
      <div className="clockCard text-center px-0">
        <strong className={"mb-0"}>{days}</strong>
        <p className="bg-amber clockFormat lead px-1 mb-0 black">
          <FormattedMessage id="timeCounter.day" defaultMessage="Day"/>
        </p>
      </div>
      <div className="clockCard text-center px-0">
        <strong className={"mb-0"}>{hours}</strong>
        <p className="bg-amber clockFormat lead px-1 mb-0 black"> <FormattedMessage id="timeCounter.hour" defaultMessage="Hour"/> </p>
      </div>
      <div className="clockCard text-center px-0">
        <strong className={"mb-0"}>{minutes}</strong>
        <p className="bg-amber clockFormat lead px-1 mb-0 black"> <FormattedMessage id="timeCounter.minute" defaultMessage="Minute"/> </p>
      </div>
      <div className="clockCard text-center px-0">
        <strong className={"mb-0"}>{seconds}</strong>
        <p className="bg-amber clockFormat lead px-1 mb-0 black"> <FormattedMessage id="timeCounter.second" defaultMessage="Second"/> </p>
      </div>
    </React.Fragment>
  )
}


class CountDown extends React.Component {
  state = {
    event: {}
  }

  eventId = activeEventId
  loadEvent = function () {
    fireDb.collection("events").doc(this.eventId).get().then(sn => {
      this.setState({
        event: sn.data()
      })
    })
  }

  componentDidMount() {
    this.loadEvent()
  }

  render() {
    let eventStart = new Date("2020-12-01T08:00:00")
    let {event} = this.state

    if(typeof event.start_time === "object")
      eventStart = new Date(event.start_time.seconds * 1000)

    return <div className={"d-flex w350px"}>
      <Countdown
        date={eventStart}
        renderer={RenderTimer}
      />
    </div>
  }
}

export default CountDown
