import {fireAuth, fireDb} from "../../../authServices/firebase/firebaseConfig";
import {history} from "../../../history";
import {hide_global_preloader, show_global_preloader} from "../../../layouts/HorizontalLayout";

const createProfileDocIfNotExists = (uid, dn) => {
  if (typeof uid !== "undefined" && uid) {
    fireDb.collection("user").doc(uid).get().then(user_snapshot => {
      let user_sn_data = user_snapshot.data()
      if (typeof user_sn_data === "undefined" || !user_sn_data) {
        fireDb.collection("user").doc(uid).set({name: dn, profileDetails: {}}).then(()=>{});
      }
    });
  }
}

export const login = (state = {userRole: localStorage.getItem("userRole") || '["isVisitor"]'}, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("userEmail", action.payload.email);
      localStorage.setItem("userName", action.payload.name);
      localStorage.setItem("userUid", action.payload.userUid);

      createProfileDocIfNotExists(
        action.payload.userUid,
        action.payload.name
      )

      return {...state, values: action.payload}
    }
    case "CHANGE_ROLE": {
      localStorage.setItem("userRole", action.userRole);
      return {...state, userRole: action.userRole}
    }
    case "LOGOUT": {
      show_global_preloader()
      fireAuth().signOut().then(function () {
        // localStorage.clear()
        localStorage.removeItem("isLoggedIn")
        localStorage.removeItem("userAvatar")
        localStorage.removeItem("userEmail")
        localStorage.removeItem("userName")
        localStorage.removeItem("userUid")
        localStorage.removeItem("userTalkSignature")
        localStorage.setItem("userRole", '["isVisitor"]')

        history.push("/pages/login")
        hide_global_preloader()
      })
      return {...state, userRole: action.userRole}
    }
    default: {
      return state
    }
  }
}
