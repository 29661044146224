import React from "react"
import {
  Button,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge
} from "reactstrap"
import * as Icon from "react-feather"
import {history} from "../../../history"
import authReducers from "../../../redux/reducers/auth";
import {createStore} from "redux";

import defaultAvatar from "../../../assets/img/profile/avatar.png"
import {fireAuth, fireDb} from "../../../authServices/firebase/firebaseConfig";
import "../../../assets/custom/scss/account.scss"
import {FormattedMessage} from "react-intl";
import {activeEventId, backendApiBaseUrl, changeUserLang, getCurrentUserLang} from "../../../App";
import {GiTicket} from "react-icons/gi";
import {BiMessage} from "react-icons/bi";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import NavbarNotification from "./NavbarNotification";
import "../../../assets/img/logo/uzakrota-logo-dark.png"
import ProfileFillWarning from "./ProfileFillWarning";
import InviteFriends from "./InviteFriends";

// const isLocal = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")

class NavbarUser extends React.PureComponent {
  state = {
    event: {},
    navbarSearch: false,
    suggestions: [],
    userAvatar: localStorage.getItem("userAvatar") || defaultAvatar,
    userName: localStorage.getItem('userName') || 'Kullanıcı',
    role: localStorage.getItem("userRole")
  }

  eventId = activeEventId
  loadEvent = function () {
    fireDb.collection("events").doc(this.eventId).get().then(sn => {
      this.setState({
        event: sn.data()
      })
    })
  }

  userLogout() {
    let store = createStore(authReducers);
    store.dispatch({
      type: "LOGOUT",
      payload: {}
    });

  }

  constructor(props) {
    super(props);

    this.userDetailsChangedHandle = this.userDetailsChangedHandle.bind(this);
    fireAuth().onAuthStateChanged(user => {
      if (user) {
        this.loadNavAvatar()
        if (localStorage.getItem("userTalkSignature") === null || localStorage.getItem("userTalkSignature") === "") {
          fireAuth().currentUser.getIdToken().then(token => {
            fetch(`${backendApiBaseUrl}/api/get-talk-signature`, {
              method: 'GET',
              headers: {
                "Authorization": token
              }
            }).then(r => r.json())
              .then(r => {
                if (typeof r === "object" && typeof r.signature === "string")
                  localStorage.setItem("userTalkSignature", r.signature);
              }).catch(err => {
              console.log(err);
            })
          })
        }
      }
    });
  }


  UserDropdown = () => {
    return (
      <DropdownMenu right>
        <DropdownItem tag="a" onClick={e => {
          history.push('/account/edit');
        }}>
          <Icon.Settings size={14} className="mr-50"/>
          <span className="align-middle"><FormattedMessage id={"accountMenu.myAccount"}/></span>
        </DropdownItem>
        <DropdownItem tag="a" onClick={e => {
          history.push('/account/tickets')
        }}>
          <GiTicket size={14} className={"mr-50"}/>
          <span className="align-middle"><FormattedMessage id={"accountMenu.myTickets"}/></span>
        </DropdownItem>
        <DropdownItem tag="a" onClick={e => {
          history.push('/account/billing')
        }}>
          <Icon.CreditCard size={14} className="mr-50"/>
          <span className="align-middle"><FormattedMessage id={"accountMenu.billing"}/></span>
        </DropdownItem>
        <DropdownItem
          tag="a"
          href="#"
          onClick={e => {
            history.push('/account/pending-orders')
          }}
        >
          <Icon.Loader size={14} className="mr-50"/>
          <span className="align-middle"><FormattedMessage id={"accountMenu.pendingOrders"}/></span>
        </DropdownItem>
        <DropdownItem
          tag="a"
          href="#"
          onClick={e => {
            history.push('/pm')
          }}
        >
          <BiMessage size={14} className={"mr-50"}/>
          <span className="align-middle"><FormattedMessage id={"navbar.messageBox"}/></span>
        </DropdownItem>

        <DropdownItem divider/>
        <DropdownItem
          tag="a"
          href="#"
          onClick={e => this.userLogout()}
        >
          <Icon.Power size={14} className="mr-50"/>
          <span className="align-middle"><FormattedMessage id={"accountMenu.logOut"}/></span>
        </DropdownItem>
      </DropdownMenu>
    )
  }

  userDetailsChangedHandle() {
    this.setState({
      userAvatar: localStorage.getItem("userAvatar") || defaultAvatar,
      userName: localStorage.getItem("userName") || 'Kullanıcı',
    });
  }

  componentDidMount() {
    // axios.get("/api/main-search/data").then(({data}) => {
    //   this.setState({suggestions: data.searchResult})
    // })
    this.loadEvent()
    window.addEventListener('userDetailsChanged', this.userDetailsChangedHandle);
  }

  componentWillUnmount() {
    window.removeEventListener('userDetailsChanged', this.userDetailsChangedHandle);
  }

  loadNavAvatar = () => {
    fireDb.collection("user").doc(fireAuth().getUid()).get().then(user_detail => {
      let user_detail_data = user_detail.data() || {};
      if (typeof user_detail_data.avatar === "string" && user_detail_data.avatar) {
        localStorage.setItem("userAvatar", user_detail_data.avatar);
        this.userDetailsChangedHandle();
      }

      if (typeof user_detail_data.language === "string" && user_detail_data.language !== getCurrentUserLang()) {
        changeUserLang(user_detail_data.language);
      }
    });
  }

  render() {
    let userName = this.state.userName
    if(typeof userName === "string" && userName.length > 20)
      userName = userName.substr(0,17) + '...'

    return (
      <>
        {window.innerWidth > 1300 ? <UncontrolledTooltip placement={"left"} target={"myInbox"}><FormattedMessage
          id={"navbar.messageBox"}/></UncontrolledTooltip> : ""}
        <ul className="nav navbar-nav navbar-nav-user float-right">
          <NavItem className={"pt10px mt5px mr10px d-none d-md-inline-block"}>
          </NavItem>

          <NavbarNotification />
          {window.innerWidth > 1300 ? <li className={"navbar-nav pl-1"}>
            <Button
              onClick={() => {
                history.push('/pm')
              }}
              color={""} className={"position-relative rounded-circle p-0"} id={"myInbox"}>
              <BiMessage size={22}/>
              {this.props.unreadMessage > 0 && <Badge pill color="primary" className="unread-message-badge badge-up">
                {" "}
                {this.props.unreadMessage}{" "}
              </Badge>}
            </Button>
          </li> : ""}
          <InviteFriends />
          {window.innerWidth >= 1200 && <ProfileFillWarning/>}
          {window.innerWidth <= 500 ? <div className={"d-inline-block lg-d-none"}>
            <img  />
          </div> : ""}
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className={"nav-link dropdown-user-link"}>
              <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {userName} <Icon.ChevronDown/>
              </span>
              </div>
              <span data-tour="user">
              <img
                src={this.state.userAvatar}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
            </DropdownToggle>
            {this.UserDropdown()}
          </UncontrolledDropdown>
        </ul>
      </>
    )
  }
}


export default NavbarUser
