import React, {Suspense, lazy} from "react"
import {Router, Switch, Route} from "react-router-dom"
import {history} from "./history"
import {connect} from "react-redux"
import Spinner from "./components/@vuexy/spinner/Loading-spinner"
import {ContextLayout} from "./utility/context/Layout"
import withTracker from "./components/Tracking/withTracker";
import {fireReal} from "./authServices/firebase/firebaseConfig";
// Route-based code splitting

// Tickets
const BuyTicket = lazy(() => import("./views/buy-ticket/BuyTicket"))
const BuyTicketStep2 = lazy(() => import("./views/buy-ticket/BuyTicketStep2"))

const UserProfile = lazy(() => import("./views/users/Profile"))

// Stands
const BuyStand = lazy(() => import("./views/buy-stand/BuyStand"))
const BuyStandStep2 = lazy(() => import("./views/buy-stand/BuyStandStep2"))

// Home
const Home = lazy(() => import("./views/pages/Home"))
const login = lazy(() => import("./views/pages/authentication/login/Login"))
const register = lazy(() => import("./views/pages/authentication/register/Register"))
const forgotPassword = lazy(() => import("./views/pages/authentication/ForgotPassword"))
const userEditProfile = lazy(() => import("./views/account/EditProfile"))
const userPm = lazy(() => import("./views/account/PM"))
const userTickets = lazy(() => import("./views/account/Tickets"))
const userBilling = lazy(() => import("./views/account/Billing"))
const pendingOrders = lazy(() => import("./views/account/PendingOrders"))

const AgendaIndex = lazy(() => import("./views/pages/agenda/Index"))
const StageIndex = lazy(() => import("./views/pages/stage/Index"))
const ExpoIndex = lazy(() => import("./views/pages/expo/Index"))
const ExpoSingle = lazy(() => import("./views/pages/expo/Single"))
const ExpoMeeting = lazy(() => import("./views/pages/expo/joinMeeting"))
const SocialIndex = lazy(() => import("./views/pages/social/Index"))

// misc
const comingSoon = lazy(() => import("./views/pages/misc/ComingSoon"))
const error404 = lazy(() => import("./views/pages/misc/error/404"))
const error500 = lazy(() => import("./views/pages/misc/error/500"))
const authorized = lazy(() => import("./views/pages/misc/NotAuthorized"))
const maintenance = lazy(() => import("./views/pages/misc/Maintenance"))

// Speaker
const speakerDashboard = lazy(() => import("./views/speaker/index"))
const joinMeeting = lazy(() => import('./views/speaker/join'))

// stand manager
const standManager = lazy(() => import("./views/stand-manager/StandManager"))
const standManageSingle = lazy(() => import("./views/stand-manager/ManageSingle"))
const standManageVisitors = lazy(() => import("./views/stand-manager/Visitors"))
const standManageIdCards = lazy(() => import("./views/stand-manager/IdCards"))
const standManageMeetings = lazy(() => import("./views/stand-manager/Meetings"))

// Staff Panel
const staffPanel = lazy(() => import("./views/staff/StaffPanel"))
const staffJoinMeeting = lazy(() => import("./views/staff/join"))
const staffManageBookings = lazy(() => import("./views/staff/ManageBookings"))

// ADMIN
const adminLayouts = {
  dashbaord: lazy(() => import("./views/admin/dashboard")),
  listUsers: lazy(() => import("./views/admin/users/list")),
  activityManage: lazy(() => import("./views/admin/chat/manage-activity")),
  standMeetingStatus: lazy(() => import("./views/admin/stands/meeting-status")),
  chatManage: lazy(() => import("./views/admin/chat/manage-chat")),
  addUser: lazy(() => import("./views/admin/users/newUser")),
  sendEmail: lazy(() => import("./views/admin/users/send-email")),
  signInUser: lazy(() => import("./views/admin/users/sign-in-user")),
  editEventProgram: lazy(() => import("./views/admin/events/program/index")),
  listEvents: lazy(() => import("./views/admin/events/list")),
  addEvent: lazy(() => import("./views/admin/events/newEvent")),
  editEvent: lazy(() => import("./views/admin/events/editEvent")),
  listSales: lazy(() => import("./views/admin/sales/list")),
  listTickets: lazy(() => import("./views/admin/tickets/list")),
  listStands: lazy(() => import("./views/admin/stands/list")),
  listDiscountCoupons: lazy(() => import("./views/admin/discount-coupons/list")),
  orderDetail: lazy(() => import("./views/admin/sales/OrderDetail")),
  FaqList: lazy(() => import("./views/admin/content/faq")),
  meetingManagement: lazy(() => import("./views/admin/meetings/list")),
  sponsorManagement: lazy(() => import("./views/admin/sponsors/ListSponsors")),
  bannerManagement: lazy(() => import("./views/admin/banners/ListBanners")),
  zoomAccountsManagement: lazy(() => import("./views/admin/meetings/ZoomAccounts")),
  notifications: lazy(() => import("./views/admin/notifications/Index")),
}


// Set Layout and Component Using App Route
const RouteConfig = ({
                       component: Component,
                       fullLayout,
                       permission,
                       user,
                       ...rest
                     }) => (
  <Route
    {...rest}
    render={props => {
      return (
        <ContextLayout.Consumer>
          {context => {
            let LayoutTag =
              fullLayout === true
                ? context.fullLayout
                : context.state.activeLayout === "horizontal"
                ? context.horizontalLayout
                : context.VerticalLayout

            let instant_data = {}

            fireReal.ref("instant_commands")
              .orderByChild('createdAt')
              .startAt(Date.now())
              .on("child_added", function (item) {
                instant_data = item.val()
                if (typeof instant_data.command === "string" && instant_data.command === "F5") {
                  window.location.reload()
                }
              });


            return (
              <LayoutTag {...props} permission={props.user}>
                <Suspense fallback={<Spinner/>}>
                  <Component {...props} />
                </Suspense>
              </LayoutTag>
            )
          }}
        </ContextLayout.Consumer>
      )
    }}
  />
)


const AdminRouteConfig = ({
                            component: Component,
                            fullLayout,
                            permission,
                            user,
                            ...rest
                          }) => (
  <Route
    {...rest}
    render={props => {
      return (
        <ContextLayout.Consumer>
          {context => {
            let LayoutTag = context.horizontalLayout
            return (
              <LayoutTag {...props} permission={props.user}>
                <Suspense fallback={<Spinner/>}>
                  <Component {...props} />
                </Suspense>
              </LayoutTag>
            )
          }}
        </ContextLayout.Consumer>
      )
    }}
  />
)

const mapStateToProps = state => {
  return {
    user: state.auth.login.userRole
  }
}

const AppRoute = connect(mapStateToProps)(RouteConfig)
const AdminRoute = connect(mapStateToProps)(AdminRouteConfig)

class AppRouter extends React.Component {
  render() {
    return (
      // Set the directory path if you are deploying in sub-folder

      <Router history={history}>
        <Switch>
          <AppRoute exact component={withTracker(Home, {})} path="/"/>
          <AppRoute exact component={BuyTicket} path="/buy-ticket" fullLayout/>
          <AppRoute exact component={BuyTicketStep2} path="/buy-ticket/:ticketTypeId/:quantity" fullLayout/>
          <AppRoute exact component={UserProfile} path="/user/:userId"/>


          <AppRoute exact component={BuyStand} path="/buy-stand" fullLayout/>
          <AppRoute exact component={BuyStandStep2} path="/buy-stand/:standTypeId/:quantity" fullLayout/>

          <AppRoute component={login} path="/pages/login" fullLayout/>
          <AppRoute component={register} path="/pages/register" fullLayout/>
          <AppRoute component={forgotPassword} path="/pages/forgot-password" fullLayout/>
          <AppRoute component={ExpoSingle} path="/expo/:standId"/>
          <Route component={ExpoMeeting} path="/expo-meeting/:meetingId"/>
          <AppRoute component={ExpoIndex} path="/expo"/>
          <AppRoute component={AgendaIndex} path="/agenda"/>
          <AppRoute component={withTracker(StageIndex, {})} path="/stage/:stageId"/>
          <AppRoute component={withTracker(StageIndex, {})} path="/stage"/>
          <AppRoute component={SocialIndex} path="/social"/>
          <AppRoute component={SocialIndex} path="/adminOnly"/>
          <AppRoute component={userEditProfile} path="/account/edit"/>
          <AppRoute component={userPm} path="/pm"/>
          <AppRoute component={userTickets} path="/account/tickets"/>
          <AppRoute component={userBilling} path="/account/billing"/>
          <AppRoute component={pendingOrders} path="/account/pending-orders"/>

          <AppRoute path="/misc/coming-soon" component={comingSoon} fullLayout/>
          <AppRoute path="/misc/error/500" component={error500} fullLayout/>
          <AppRoute path="/misc/not-authorized" component={authorized} fullLayout/>
          <AppRoute path="/misc/maintenance" component={maintenance} fullLayout/>

          <Route component={joinMeeting} path="/speaker/join/:meetingId"/>
          <AppRoute component={speakerDashboard} path="/speaker"/>

          <AppRoute component={standManageVisitors} path="/stand-manager/:standId/visitors"/>
          <AppRoute component={standManageIdCards} path="/stand-manager/:standId/id-cards"/>
          <AppRoute component={standManageMeetings} path="/stand-manager/:standId/meetings"/>
          <AppRoute component={standManageSingle} path="/stand-manager/:standId"/>
          <AppRoute component={standManager} path="/stand-manager"/>

          <Route component={staffJoinMeeting} path="/staff-panel/join-meeting/:standId"/>
          <AppRoute component={staffManageBookings} path="/staff-panel/manage-bookings"/>
          <AppRoute component={staffPanel} path="/staff-panel"/>


          <AdminRoute path="/admin/meetings" component={adminLayouts.meetingManagement}/>
          <AdminRoute path="/admin/sponsor-management" component={adminLayouts.sponsorManagement}/>
          <AdminRoute path="/admin/banner-management" component={adminLayouts.bannerManagement}/>
          <AdminRoute path="/admin/zoom-accounts" component={adminLayouts.zoomAccountsManagement}/>

          <AdminRoute path="/admin/users/new" component={adminLayouts.addUser}/>
          <AdminRoute path="/admin/users/send-email" component={adminLayouts.sendEmail}/>
          <AdminRoute path="/admin/users/sign-in-user" component={adminLayouts.signInUser}/>
          <AdminRoute path="/admin/users" component={adminLayouts.listUsers}/>

          <AdminRoute path="/admin/manage-activity" component={adminLayouts.activityManage}/>
          <AdminRoute path="/admin/manage-chat" component={adminLayouts.chatManage}/>
          <AdminRoute path="/admin/stand-meeting-status" component={adminLayouts.standMeetingStatus}/>
          <AdminRoute path="/admin/notifications" component={adminLayouts.notifications}/>

          <AdminRoute path="/admin/discount-coupons/list" component={adminLayouts.listDiscountCoupons}/>

          <AdminRoute path="/admin/events/edit/:eventId/program" component={adminLayouts.editEventProgram}/>
          <AdminRoute path="/admin/events/edit/:eventId" component={adminLayouts.editEvent}/>
          <AdminRoute path="/admin/events/list" component={adminLayouts.listEvents}/>
          <AdminRoute path="/admin/events/new" component={adminLayouts.addEvent}/>

          <AdminRoute path="/admin/tickets/list" component={adminLayouts.listTickets}/>
          <AdminRoute path="/admin/stands/list" component={adminLayouts.listStands}/>

          <AdminRoute path="/admin/sales/list" component={adminLayouts.listSales}/>
          <AdminRoute path="/admin/sales/:orderId" component={adminLayouts.orderDetail}/>
          <AdminRoute path="/admin/sales" component={adminLayouts.listSales}/>
          <AdminRoute path="/admin/content/faq" component={adminLayouts.FaqList}/>
          <AdminRoute path="/admin" component={adminLayouts.dashbaord}/>


          <AppRoute path="*" component={error404} fullLayout/>
        </Switch>
      </Router>
    )
  }
}

export default AppRouter
