import React from "react"
import {history} from "../../../history"
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, ListGroup, ListGroupItem,
  UncontrolledDropdown,
  UncontrolledPopover,
  UncontrolledTooltip
} from "reactstrap";
import {fireAuth, fireDb} from "../../../authServices/firebase/firebaseConfig";
import {BiCheck, BiListCheck, BsCheckAll, FiCheckSquare, FiInfo, FiSquare} from "react-icons/all";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

class ProfilePercentCalculate {
  max = 100
  point = 0
  data = {}

  pointMap = [
    {
      completed: false,
      field: "name",
      label: <FormattedMessage id={"navbar.profileRank.items.name"}/>,
      depth: 0,
      point: 15
    },
    {
      completed: false,
      field: "avatar",
      label: <FormattedMessage id={"navbar.profileRank.items.avatar"}/>,
      depth: 0,
      point: 15
    },
    {
      completed: false,
      field: "bio",
      label: <FormattedMessage id={"navbar.profileRank.items.bio"}/>,
      depth: 1,
      point: 10
    },
    {
      completed: false,
      field: "company",
      label: <FormattedMessage id={"navbar.profileRank.items.company"}/>,
      depth: 1,
      point: 5
    },
    {
      completed: false,
      field: "department",
      label: <FormattedMessage id={"navbar.profileRank.items.department"}/>,
      depth: 1,
      point: 3
    },
    {
      completed: false,
      field: "industry",
      label: <FormattedMessage id={"navbar.profileRank.items.industry"}/>,
      depth: 1,
      point: 3
    },
    {
      completed: false,
      field: "position",
      label: <FormattedMessage id={"navbar.profileRank.items.position"}/>,
      depth: 1,
      point: 3
    },
    {
      completed: false,
      field: "profileTitle",
      label: <FormattedMessage id={"navbar.profileRank.items.profileTitle"}/>,
      depth: 1,
      point: 3
    },
    {
      completed: false,
      field: "website",
      label: <FormattedMessage id={"navbar.profileRank.items.website"}/>,
      depth: 1,
      point: 0
    },
    {
      completed: false,
      field: "facebook",
      label: <FormattedMessage id={"navbar.profileRank.items.facebook"}/>,
      depth: 1,
      point: 5
    },
    {
      completed: false,
      field: "twitter",
      label: <FormattedMessage id={"navbar.profileRank.items.twitter"}/>,
      depth: 1,
      point: 5
    },
    {
      completed: false,
      field: "instagram",
      label: <FormattedMessage id={"navbar.profileRank.items.instagram"}/>,
      depth: 1,
      point: 5
    },
    {
      completed: false,
      field: "linkedin",
      label: <FormattedMessage id={"navbar.profileRank.items.linkedin"}/>,
      depth: 1,
      point: 5
    },
  ]

  getData = () => {
    let uid = localStorage.getItem("userUid") || (fireAuth().currentUser !== null && fireAuth().currentUser.uid)
    if(!uid)
      return new Promise(function (resolve) { resolve({}) })

    return fireDb.collection("user").doc(uid).get().then(snap => {
      let data = snap.data()
      this.max = (this.pointMap).reduce((a, b) => a + (b.point || 0), 0);
      (this.pointMap).map((item, i) => {
        if (
          typeof data === "object" && (item.depth === 0 && typeof data[item.field] === "string" && data[item.field] !== ""
          || item.depth === 1 && typeof data['profileDetails'][item.field] === "string" && data['profileDetails'][item.field] !== "")
        ) {
          this.point += item.point;
          (this.pointMap)[i].completed = true;
        }
      });
    })
  }

  getPercent() {
    let percent = this.point * 100 / this.max
    return parseInt(percent + "")
  }

}

class ProfileFillWarning extends React.Component {

  profiler = null

  constructor(props) {
    super(props);
    let self = this

    if (window.addEventListener) {  // all browsers except IE before version 9
      window.addEventListener("message", this.OnMessage, false);
    } else {
      if (window.attachEvent) {   // IE before version 9
        window.attachEvent("onmessage", this.OnMessage);
      }
    }
  }

  OnMessage = (e) => {
    let self = this
    let {data} = e
    switch (data.event) {
      case 'profile_updated':
        self.calcProfile()
        break;
    }
  }

  calcProfile = () => {
    let profile = new ProfilePercentCalculate()
    let self = this
    this.profiler = profile

    profile.getData().then(function () {
      self.setState({
        percent: profile.getPercent()
      })
    })
  }

  state = {
    percent: 0
  }

  componentDidMount() {
    this.calcProfile()
  }

  render() {
    let {percent} = this.state
    return <>

      <UncontrolledDropdown tag="li" className="dropdown-profile-calc">
        <DropdownToggle tag="a">
          <div id={"profile-fill"} className={"cursor-pointer"}
            // onClick={() => {
            // history.push('/account/edit')
            //}}
          >
            <div style={{width: 40, marginTop: 10}}>
              <CircularProgressbar
                value={percent}
                text={`${percent}%`}
                styles={buildStyles({
                  rotation: 0.25,
                  strokeLinecap: 'butt',
                  textSize: '32px',
                  pathTransitionDuration: 1.5,
                  pathColor: percent < 20 ? "lightcoral" : "#fbba00",
                  textColor: percent < 20 ? "lightcoral" : "#fbba00",
                  trailColor: '#CCC',
                  backgroundColor: "#fbba00",
                })}/>
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu bottom style={{width: 215}}>
          <div className="pt-1 pl-1 pr-1">
            {percent < 99 &&
            <h6 className={"border-bottom pb-1 mb-1 text-info"}><FiInfo/> <FormattedMessage
              id={"navbar.profileRank.description"}/></h6>}
            {percent > 99 &&
            <h6 className={"border-bottom pb-1 mb-1 text-success"}><BsCheckAll size={20}/> <FormattedMessage
              id={"navbar.profileRank.description_success"}/></h6>}
            {this.profiler !== null && typeof this.profiler.pointMap === "object" && this.profiler.pointMap !== null ? (this.profiler.pointMap).map(item => {
              return <p className={item.completed ? "text-success" : "text-primary"}>{item.completed ?
                <FiCheckSquare size={16}/> : <FiSquare size={16}/>} {item.label}</p>
            }) : ""}
          </div>
          <div className="text-center p-1 border-top">
            <Link to={"/account/edit"} className={"btn btn-sm btn-primary"}><FormattedMessage
              id={"navbar.profileRank.editProfile"}/></Link>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  }
}

export default ProfileFillWarning
