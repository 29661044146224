import React from "react"
import * as Icon from "react-feather"

import {
  HiOutlineHome
} from "react-icons/hi"
import {
  GiTicket
} from "react-icons/gi"
import {AiOutlineShop} from "react-icons/all";
import {TiCogOutline} from "react-icons/ti";
import {GrMicrophone} from "react-icons/gr";
import {FaUsersCog} from "react-icons/fa";
import {RiMapPinUserFill} from "react-icons/ri";
import {FaRegHandshake} from "react-icons/fa";

const iconSize = "21px"
const iconColor = "#373737"

export default function GlobalNavigationConfig (string_object) {
  return [
    {
      id: "home",
      title: string_object.lobby,
      type: "item",
      icon: <HiOutlineHome size={iconSize} color={iconColor} />,
      permissions: ["admin", "visitor", "speaker", "attendee", "supervisitor"],
      navLink: "/"
    },
    {
      id: "buyTicket",
      title: string_object.buyTicket,
      type: "item",
      icon: <GiTicket size={iconSize}  color={iconColor} />,
      permissions: ["visitor", "speaker", "attendee", "supervisitor"],
      navLink: "/buy-ticket"
    },
    {
      id: "buyStand",
      title: string_object.buyStand,
      type: "item",
      icon: <AiOutlineShop size={iconSize}  color={iconColor} />,
      permissions: ["visitor", "speaker", "attendee","supervisitor"],
      navLink: "/buy-stand"
    },
    {
      id: "stage",
      title: string_object.stage,
      type: "item",
      // badge: 'success',
      // badgeText: (<div><Radio className={"ml-0"} /></div>),
      icon: <Icon.Video size={iconSize}  color={iconColor} />,
      permissions: ["admin", "visitor", "attendee", "supervisitor"],
      navLink: "/stage"
    },
    {
      id: "agenda",
      title: string_object.agenda,
      type: "item",
      icon: <Icon.Calendar size={iconSize}  color={iconColor} />,
      permissions: [
        "admin","attendee","speaker","supervisitor","standmanager","staff", "visitor"
      ],
      navLink: "/agenda"
    },
    {
      id: "expo",
      title: string_object.expo,
      type: "item",
      icon: <FaRegHandshake size={iconSize}  color={iconColor} />,
      permissions: [
        "admin", "supervisitor", "visitor", "attendee"
      ],
      navLink: "/expo",
      parentOf: [
        "/expo/*"
      ],
      parentOfPattern: /^\/expo/
    },
    {
      id: "social",
      title: string_object.social,
      type: "item",
      icon: <Icon.Users size={iconSize}  color={iconColor} />,
      permissions: [
        "admin", "supervisitor", "visitor", "attendee"
      ],
      navLink: "/social"
    },
    {
      id: "speakerPanel",
      title: string_object.speakerPanel,
      type: "item",
      icon: <GrMicrophone size={iconSize}  color={iconColor} />,
      permissions: ["admin", "speaker", "supervisitor"],
      navLink: "/speaker"
    },
    {
      id: "staffPanel",
      title: string_object.staffPanel,
      type: "item",
      icon: <RiMapPinUserFill size={iconSize}  color={iconColor} />,
      permissions: ["staff", "supervisitor"],
      navLink: "/staff-panel"
    },
    {
      id: "standPanel",
      title: string_object.standPanel,
      type: "item",
      icon: <FaUsersCog size={iconSize}  color={iconColor} />,
      permissions: ["standmanager", "staff", "admin", "supervisitor"],
      navLink: "/stand-manager"
    },
    {
      id: "adminPanel",
      title: string_object.adminPanel,
      type: "item",
      icon: <TiCogOutline size={iconSize}  color={iconColor} />,
      permissions: ["admin"],
      navLink: "/admin"
    },
  ]
}
