import React, {Suspense} from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import {Layout} from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import {store} from "./redux/storeConfig/store"
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
import "./index.scss"
import App from "./App";
import {fireDb} from "./authServices/firebase/firebaseConfig";

fireDb.collection("config").doc("public_options").get().then(snapshot => {
  let data = snapshot.data();
  let _options = {};
  for (const [key, value] of Object.entries(data)) {
    _options[key] = value;
  }

  localStorage.setItem("options", JSON.stringify(_options));

  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<Spinner/>}>
        <Layout>
          <App />
        </Layout>
      </Suspense>
    </Provider>,
    document.getElementById("root")
  )
  serviceWorker.unregister()

});
