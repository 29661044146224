import {CarouselProvider, Slide, Slider} from "pure-react-carousel";
import React from "react";
import 'pure-react-carousel/dist/react-carousel.es.css';
import {fireAuth, fireDb} from "../../../authServices/firebase/firebaseConfig";
import {Spinner} from "reactstrap";
import {activeEventId} from "../../../App";

class TopSponsors extends React.Component {

  eventId = activeEventId
  state = {
    sponsors: [],
    sponsorsLoading: true
  }

  constructor(props) {
    super(props);
    fireAuth().onAuthStateChanged(user => {
      if (user)
        this.loadTopSponsors()
    })
  }

  loadTopSponsors = () => {
    fireDb
      .collection("sponsors")
      .where("event_id", "==", this.eventId)
      .where("type", "==", "navbar")
      .where("hidden", "==", false)
      .orderBy("order", "asc")
      .limit(999)
      .get()
      .then(sn => {
        if (sn.docs.length) {
          let items = []
          sn.forEach(item => {
            items.push(item.data())
            this.setState({
              sponsors: items,
              sponsorsLoading: false
            })
          })
        } else {
          this.setState({sponsorsLoading: false})
        }
        return ""
      })
  }

  render() {
    let {sponsors, sponsorsLoading} = this.state
    return <div className={"top-sponsors pt5px pl-0"}>
      <CarouselProvider
        visibleSlides={3}
        infinite={true}
        naturalSlideWidth={100}
        naturalSlideHeight={32}
        totalSlides={sponsors.length}
        interval={5000}
        isPlaying={true}
        dragEnabled={false}
      >
        <Slider>
          {(sponsors.length) ? sponsors.map((item, i) => {
            return <Slide title={item.title} index={i}><img src={item.image} height={32}/></Slide>
          }) : sponsorsLoading ? <Spinner size={"sm"} color={"primary"}/> : ""}
        </Slider>
      </CarouselProvider>
    </div>
  }
}

export default TopSponsors
