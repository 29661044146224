import React from "react"
import {Button, ListGroup, ListGroupItem, Spinner} from "reactstrap";
import {history} from "../../../../history";
import {FormattedMessage} from "react-intl";
import {fireDb} from "../../../../authServices/firebase/firebaseConfig";
import classnames from "classnames";
import {ArrowDown, ArrowRight} from "react-feather";
import {HiChatAlt} from "react-icons/all";

class HelpModal extends React.Component {
  state = {
    faq: [],
    faqLoading: true,
    activeFaq: null
  }

  componentDidMount() {
    fireDb.collection("faq").orderBy("created", "desc").get().then(d => {
      let dt = []
      d.forEach(item => dt.push(Object.assign({
        id: item.id
      }, item.data())))
      this.setState({faq: dt, faqLoading: false})
    })
  }

  render() {
    return <>
      <div className={"d-block p-3 text-center"}>
        <Button color={"primary"} onClick={() => {
          if(typeof this.props.close === "function")
            this.props.close()

          history.push("/pm?chatWith=6L7l0cw9fSgpbNnwVFj4ehnkpl13")
        }}><FormattedMessage id={"main.needASupportChatButton"}/> <HiChatAlt /></Button>
      </div>
      <div className="d-block mt-1 mb-1" style={{maxHeight: 400, overflowY: "auto"}}>
        <h2><FormattedMessage id={"main.helpDocs"}/></h2>
        {this.state.faqLoading && <div className={"p-1 text-center"}><Spinner color={"dark"}/></div>}
        {
          (this.state.faq).map(item => {
            let active = this.state.activeFaq === item.id;
            return <>
              <div className={classnames("w-100 p-1 border rounded mb-1 cursor-pointer", {
                shadow: active
              })} onClick={() => {
                this.setState({activeFaq: active ? null : item.id})
              }}>
                <div className={classnames("d-flex", {"text-success": active})}>
                  <h5 className={"w-100"}>{item.title}</h5>
                  {!active ? <ArrowRight size={15}/> : <ArrowDown size={15}/>}
                </div>
                <div className={classnames("mt-1 pt-1 border-top", {
                  "d-none": !active
                })}>{item.content}</div>
              </div>
            </>
          })
        }
      </div>
    </>
  }
}

export default HelpModal
