import React from "react"
import {DropdownMenu, Media, Badge, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {Bell, Smile} from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar"
import {fireAuth, fireReal} from "../../../authServices/firebase/firebaseConfig";
import moment from "moment";
import * as classnames from "classnames";
import {VscMail, VscMailRead} from "react-icons/vsc";
import {FormattedMessage} from "react-intl";

const refName = "notifications"

class NavbarNotification extends React.Component {
  state = {
    dropdownNotification: false,
    notifications: [],
    notificationUnreadCount: 0,
    unreadKeys: []
  }

  loadNotifications = () => {
    let userUid = fireAuth().currentUser.uid || localStorage.getItem("userUid")
    let self = this, noti_val = {}


    const realTimeNotify = function (item) {
      let notifications = self.state.notifications
      noti_val = item.val()
      notifications.push(Object.assign(noti_val, {id: item.key}))
      self.setState({
        notifications: notifications.reverse()
      })
    }

    const realTimeNotifyChanged = function (item) {
      let notifications = self.state.notifications
      noti_val = item.val()
      noti_val = Object.assign(noti_val, {id: item.key})
      notifications = notifications.map(x => x.id === item.key ? noti_val : x)

      self.setState({
        notifications: notifications.reverse()
      })
    }

    fireReal.ref(refName + '/' + userUid).orderByChild("createdAt").on("child_changed", realTimeNotifyChanged);
    fireReal.ref(refName + '/' + userUid)
      .orderByChild("createdAt").limitToLast(10).on("child_added", realTimeNotify)
  }

  constructor(props) {
    super(props);
    fireAuth().onAuthStateChanged(user => {
      if (user) {
        this.loadNotifications()
      }
    })
  }

  markAsRead = (itemId,isRead) => {
    if(isRead)
      return false

    let userUid = fireAuth().currentUser.uid || localStorage.getItem("userUid")
    fireReal.ref(refName + '/' + userUid + '/' + itemId)
      .update({isRead: true})
      .then(result => {
      })
  }

  singleNotifyRow = (item) => {
    let unRead = typeof item.isRead === "boolean" && !item.isRead
    let theme = typeof item.theme === "string" ? item.theme : "info"
    let content = ""
    let createdTime = item.createdAt

    if (typeof item.content === "string" && item.content !== "")
      content = item.content

    if (content === "" && typeof item.data !== "undefined" && typeof item.data.content === "string")
      content = item.data.content

    return <div onClick={() => this.markAsRead(item.id,typeof item.isRead === "boolean" && item.isRead === true)} className="d-flex justify-content-between">
      <Media className="d-flex align-items-start">
        <Media left href="#" className={classnames({
          "primary": unRead,
          "dark": !unRead
        })}>
          {unRead ? <VscMail size={15}/> : <VscMailRead size={15}/>}
        </Media>
        <Media body>
          <Media
            heading
            className="media-heading"
            tag="p"
          >{content}</Media>
        </Media>
        <small>
          <time
            className="media-meta"
            dateTime={moment(new Date(createdTime)).format()}
          >
            {moment(new Date(createdTime)).fromNow()}
          </time>
        </small>
      </Media>
    </div>
  }

  render() {
    let {dropdownNotification, notifications} = this.state
    let count = 0
    if (notifications.length) {
      count = notifications.filter(x => typeof x.isRead === "boolean" && !x.isRead).length
    }

    notifications = ((notifications.filter(x => x.type === "toast")).sort(function (a, b) {
      if (a.createdAt > b.createdAt) {
        return -1
      }
      if (a.createdAt < b.createdAt) {
        return 1
      }
    }))

    return <>

      <UncontrolledDropdown
        className="dropdown-notification nav-item"
        tag="li"
      >
        <DropdownToggle
          tag="a"
          data-toggle="dropdown"
          aria-expanded={dropdownNotification}
          className="nav-link nav-link-label notification-navlink"
        >
          <Bell size={21}/>
          <Badge pill color="primary" className="unread-message-badge badge-up">
            {" "}{count}{" "}
          </Badge>
        </DropdownToggle>
        <DropdownMenu
          tag="ul"
          right
          className="dropdown-menu-media"
        >
          <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
              {count ? <h4 className={"text-white"}><FormattedMessage id={"main.navbarNotificationsCount"} values={{count: count}}/></h4> : ""}
              <span className="notification-title">
                <FormattedMessage id={"main.navbarNotificationsTitle"}/>
              </span>
            </div>
          </li>
          <PerfectScrollbar
            className="media-list overflow-hidden position-relative"
            options={{
              wheelPropagation: false
            }}
          >
            {notifications.length > 0 ? notifications.map(item => this.singleNotifyRow(item)) : <p className={"p-1 text-center"}>
              <Smile size={14} /> <FormattedMessage id={"main.navbarNotificationsNotfound"} />
            </p>}
          </PerfectScrollbar>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  }
}

export default NavbarNotification
