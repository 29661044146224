import GlobalNavigationConfig from "./navigationConfig-global";


const navigationConfigEn = GlobalNavigationConfig({
  lobby: "Lobby",
  buyTicket: "Buy Ticket", // for price
  // buyTicket: "Get Ticket", // for free
  buyStand: 'Buy Stand',
  stage: "Stage",
  agenda: "Agenda",
  expo: "Expo",
  social: "Social",
  adminPanel: "Admin Panel",
  speakerPanel: "Speaker Panel",
  standPanel: "Stand Manager",
  staffPanel: "Staff Panel"
})

export default navigationConfigEn
