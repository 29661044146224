import React, {Component} from "react"
import classnames from "classnames"
import {ContextLayout} from "../../../utility/context/Layout"
import {connect} from "react-redux"

import Hammer from "react-hammerjs"
import PerfectScrollbar from "react-perfect-scrollbar"
import {GiftedChat} from "react-web-gifted-chat";
import {fireAuth, fireDb, fireReal} from "../../../authServices/firebase/firebaseConfig";
import {UncontrolledTooltip, Button, ButtonGroup, NavItem, NavLink, Nav} from "reactstrap";
import * as Icon from "react-feather"
import {v4 as uuidv4} from 'uuid';
import moment from "moment";
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent";
import {FormattedMessage} from "react-intl";
import "../../../assets/scss/pages/app-chat.scss"
import {history} from "../../../history";
import {toast} from "react-toastify";
import {AiFillCloseCircle, AiFillCloseSquare, BiChat, CgClose} from "react-icons/all";

const emojis = [
  "😂", "🙂", "😎", "🥳", "😟", "😱"
]

class Chat extends Component {

  static getDerivedStateFromProps(props, state) {
    if (props.activePath !== state.activeItem) {
      return {
        activeItem: props.activePath
      }
    }
    // Return null if the state hasn't changed
    return null
  }

  msgRef = null

  state = {
    width: window.innerWidth,
    activeIndex: null,
    hoveredMenuItem: null,
    activeItem: this.props.activePath,
    menuShadow: false,
    ScrollbarTag: PerfectScrollbar,
    messages: [],
    user: {},
    new_message_text: "",
    hideRightChat: false
  }

  mounted = false

  constructor(props) {
    super(props);
    fireAuth().onAuthStateChanged(user => {
      if (user) {
        this.firstLoadAfterUserStateChanged(user)
        this.loadCompanyName()
      }
    });
    this.chatInputRef = React.createRef();
  }

  firstLoadAfterUserStateChanged(user) {
    if (user) {
      this.loadMessages();

      this.setState({isAuthenticated: true});
      let user_data = {}
      fireDb.collection("user").doc(user.uid).get().then(dataSnap => {
        user_data = dataSnap.data() || {}
        user_data.id = user.uid
        if (user_data) {
          this.setState({
            user: user_data
          })
        }
      })
    } else {
      this.setState({isAuthenticated: false, user: {}, messages: []});
    }
  }

  updateWidth = () => {
    if (this.mounted) {
      this.setState(prevState => ({
        width: window.innerWidth
      }))
      this.checkDevice()
    }
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      if (window !== "undefined") {
        window.addEventListener("resize", this.updateWidth, false)
      }
      this.checkDevice()
    }
  }

  loadCompanyName = () => {
    let user_id = localStorage.getItem("userUid") || fireAuth().currentUser.uid
    fireDb.collection("user").doc(user_id).get().then(snapshot => {
      let data = snapshot.data()
      if (data && typeof data.profileDetails !== "undefined") {
        if (typeof data.profileDetails === "object" && typeof data.profileDetails.company === "string" && (data.profileDetails.company).length > 0) {
          this.setState({companyName: data.profileDetails.company})
        }
      }
    })
  }

  loadMessages() {

    if (this.msgRef !== null)
      this.msgRef.off('child_added')

    const callback = snap => {
      const message = snap.val();
      message.id = snap.key;

      const {messages} = this.state;

      messages.push(message);
      this.setState({messages});
    };

    this.msgRef = fireReal.ref("/channel-global/")
    this.msgRef
      .orderByChild("hidden")
      .equalTo(false)
      .limitToLast(50)
      .on("child_added", callback);
  }

  componentWillUnmount() {
    this.mounted = false
  }

  checkDevice = () => {
    var prefixes = " -webkit- -moz- -o- -ms- ".split(" ")
    var mq = function (query) {
      return window.matchMedia(query).matches
    }

    if ("ontouchstart" in window || window.DocumentTouch) {
      this.setState({
        ScrollbarTag: "div"
      })
    } else {
      this.setState({
        ScrollbarTag: PerfectScrollbar
      })
    }
    var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("")
    return mq(query)
  }

  changeActiveIndex = id => {
    if (id !== this.state.activeIndex) {
      this.setState({
        activeIndex: id
      })
    } else {
      this.setState({
        activeIndex: null
      })
    }
  }

  handleSidebarMouseEnter = id => {
    if (id !== this.state.hoveredMenuItem) {
      this.setState({
        hoveredMenuItem: id
      })
    } else {
      this.setState({
        hoveredMenuItem: null
      })
    }
  }

  handleActiveItem = url => {
    this.setState({
      activeItem: url
    })
  }

  onAvatarClicked(avatar) {
    alert('Avatar Click!')
  }

  onSend(messages) {
    let {user} = this.state
    for (const message of messages) {
      message.hidden = false
      message.createdAt = Date.now()
      message.channel = this.state.currentChannel || null
      message.user = {
        id: user.id,
        name: user.name || "",
        avatar: user.avatar || "",
        company: this.state.companyName || ""
      }
      this.saveMessage(message);
    }
  }


  saveMessage(message) {
    if (message === null || typeof message.text !== "string" || message.text === "") {
      toast.warn(<FormattedMessage id={"wall.shareEmptyWarning"}/>);
      this.chatInputRef.current.focus()
      return false;
    }

    if (this.props.currentChannel === null) {
      this.msgRef = fireReal.ref("/channel-global/")
    } else {
      this.msgRef = fireReal.ref(`/channel-${this.props.currentChannel}/`)
    }

    return this.msgRef
      .push(message)
      .catch(function (error) {
        console.error("Error saving message to Database:", error);
      });
  }

  renderCustomView(message) {
  }

  switchChannel(new_channel) {
    this.props.changeChannel(new_channel)
    this.setState({
      messages: []
    }, () => {
      this.loadMessages()
    })
  }

  append2Text = (str) => {

    let myField = this.chatInputRef.current
    let myValue = str

    //IE support
    if (document.selection) {
      myField.focus();
      let sel = document.selection.createRange();
      sel.text = myValue;
    }
    //MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart === '0') {
      var startPos = myField.selectionStart;
      var endPos = myField.selectionEnd;
      myField.value = myField.value.substring(0, startPos)
        + myValue
        + myField.value.substring(endPos, myField.value.length);
    } else {
      myField.value += myValue;
    }
    this.setState({new_message_text: myField.value})
    this.chatInputRef.current.focus()
  }

  sendNewMessage() {
    let msgs = []
    msgs.push({
      id: uuidv4(),
      text: this.state.new_message_text
    })
    this.setState({
      new_message_text: ''
    })
    this.onSend(msgs)
  }

  getNameTitle(_name) {
    let parseName = _name.split(" ")
    let title = ""
    parseName.map(item => title += item.substr(0, 1))
    return title
  }

  limitCompanyName(name) {
    if (typeof name === "string" && name.length > 25)
      name = name.substr(0, 22) + '...'
    return name
  }

  render() {
    let {
      // visibilityState,
      // toggleSidebarMenu,
      // sidebarHover,
      // toggle,
      // color,
      sidebarVisibility,
      activeTheme,
      // collapsed,
      // activePath,
      sidebarState,
      // currentLang,
      // permission,
      // currentUser,
      // collapsedMenuPaths
    } = this.props

    let {
      menuShadow,
      // activeIndex,
      // hoveredMenuItem,
      // activeItem,
      ScrollbarTag
    } = this.state
    // let _return = <div
    //   className={classnames(
    //     `main-menu menu-fixed d-sm-none d-md-block chat-sidebar chatbox menu-light menu-accordion menu-shadow theme-${activeTheme}`,
    //     {
    //       collapsed: sidebarState === true,
    //       "hide-sidebar":
    //       // this.state.width < 1200 &&
    //         visibilityState === false
    //     }
    //   )}
    //   style={{
    //     backgroundImage: `url(${chatBg})`,
    //     backgroundSize: "cover",
    //     backgroundPosition: "center"
    //   }}>
    //   <h3
    //     style={{
    //       height: "100%",
    //       width: "100%",
    //       background: "rgba(0,0,0,.65)"
    //     }}
    //     className={"text-center text-white pt-5"}>
    //     <FormattedHTMLMessage id={"chatBar.coming"} />
    //     <hr style={{
    //       borderColor: "rgba(255,255,255,.5)",
    //       marginTop: 50
    //   }}/></h3>
    // </div>

    let scrollShadow = (container, dir) => {
      if (container && dir === "up" && container.scrollTop >= 100) {
        this.setState({menuShadow: true})
      } else if (container && dir === "down" && container.scrollTop < 100) {
        this.setState({menuShadow: false})
      } else {
        return
      }
    }


    return (
      <ContextLayout.Consumer>
        {context => {
          // let dir = context.state.direction

          // return             <React.Fragment>
          //   <Hammer
          //     onSwipe={e => {
          //       sidebarVisibility()
          //     }}
          //     direction={"DIRECTION_RIGHT"}
          //   >
          //     <div className="menu-swipe-area d-xl-none d-block vh-100"></div>
          //   </Hammer>
          //
          //   <div
          //     className={classnames(
          //       `main-menu menu-fixed d-sm-none d-md-block chat-sidebar chatbox bg-white menu-accordion menu-shadow theme-${activeTheme}`,
          //       {
          //         collapsed: sidebarState === true,
          //         "hide-sidebar":
          //         // this.state.width < 1200 &&
          //           visibilityState === false
          //       }
          //     )}
          //     onMouseEnter={() => ''}
          //     onMouseLeave={() => ''}>
          //
          //   </div>
          // </React.Fragment>

          return (
            <React.Fragment>
              <Hammer
                onSwipe={e => {
                  sidebarVisibility()
                }}
                direction={"DIRECTION_RIGHT"}
              >
                <div className="menu-swipe-area d-xl-none d-block vh-100"></div>
              </Hammer>

              <UncontrolledTooltip target={"show-chat"}>
                <FormattedMessage id={"main.showChat"}/>
              </UncontrolledTooltip>
              <Button id={"show-chat"} className={"show-chat-button rounded-circle p-0"} onClick={() => {
                if (typeof this.props.hideHandle === "function") {
                  this.props.hideHandle()
                }
              }} color={"primary"}><BiChat size={24}/></Button>


              <div
                className={classnames(
                  `main-menu menu-fixed d-sm-none d-md-block chat-sidebar chatbox bg-white menu-accordion menu-shadow theme-${activeTheme}`,
                  {
                    "hide-sidebar-right": this.state.width < 1200 || this.props.hide
                  }
                )}
                onMouseEnter={() => ''}
                onMouseLeave={() => ''}>
                <Nav tabs className={"justify-content-center border-bottom"} style={{
                  backgroundColor: "#f9f9f9"
                }}>

                  {this.props.channelList.map((ch, i) => {
                    return <NavItem className={"d-none"} key={`NavItem-${i}`}>
                      <NavLink
                        className={classnames({
                          active: this.props.currentChannel === ch.id
                        })}
                        onClick={() => {
                          this.switchChannel(ch.id)
                        }}
                      >
                        {ch.label}
                      </NavLink>
                    </NavItem>
                  })}

                  <NavItem className={"float-right"}><NavLink className={"text-primary"} onClick={() => {
                    if (typeof this.props.hideHandle !== "function") return;
                    this.props.hideHandle()
                  }}><AiFillCloseSquare size={21}/> <FormattedMessage id={"globalModal.close"} /></NavLink></NavItem>
                </Nav>

                <ScrollbarTag
                  className={classnames("main-menu-content right-main-menu-content", {
                    "overflow-hidden": ScrollbarTag !== "div",
                    "overflow-scroll": ScrollbarTag === "div"
                  })}
                  {...(ScrollbarTag !== "div" && {
                    options: {wheelPropagation: false},
                    onScrollDown: container => scrollShadow(container, "down"),
                    onScrollUp: container => scrollShadow(container, "up"),
                    onYReachStart: () =>
                      menuShadow === true &&
                      this.setState({menuShadow: false})
                  })}>
                  <GiftedChat
                    minComposerHeight={60}
                    maxComposerHeight={60}
                    renderMessage={(_this) => {
                      // let _id = (_this.currentMessage.id).replaceAll('-', '')
                      // _id = (_id).replaceAll('_', '').toLowerCase()
                      let messageText = this.formatMessageText(_this.currentMessage.text)
                      return <div key={`message-${_this.currentMessage.id}`} className={"chat-message-item"}>
                        <div className={"w-100 chat-message d-flex"} onClick={() => {
                          history.push(`/user/${_this.currentMessage.user.id}`)
                        }}>
                          <Avatar className="mr-1 avatar-sq align-self-start"
                                  color={this.getUserColor(_this.currentMessage.user.id)}
                                  id={`avatar${_this.currentMessage.id}`}
                                  content={!_this.currentMessage.user.avatar ? this.getNameTitle(_this.currentMessage.user.name) : ""}
                                  img={_this.currentMessage.user.avatar ? _this.currentMessage.user.avatar : false}
                          />
                          <div className={"align-self-start w-100"}>
                            <div className={"bubble p-1"}>
                              <span className="text">{messageText}</span>
                              <span className="time">{moment(_this.currentMessage.createdAt).format("DD/MM/YYYY HH:mm")}</span></div>
                          </div>
                        </div>
                        <UncontrolledTooltip
                          placement="top"
                          target={`avatar${_this.currentMessage.id}`}
                        >
                          {_this.currentMessage.user.name || ''}
                          {typeof _this.currentMessage.user.company === "string" && _this.currentMessage.user.company !== "" ? ` @ ${this.limitCompanyName(_this.currentMessage.user.company)}` : ""}
                        </UncontrolledTooltip>
                      </div>
                    }}
                    renderComposer={() => {
                      return <div className={"w-100"}>
                        <ButtonGroup className={"text-center border border-left-0 border-right-0 emoji-group"}>
                          {emojis.map((emoji, i) => {
                            return <Button
                              key={`buttonEmoji-${i}`}
                              style={{
                                padding: "3px 5px",
                                lineHeight: "34px",
                                fontSize: "20px"
                              }}
                              color={"flat-success"} className={"rounded-circle"}
                              onClick={() => this.append2Text(emoji)}>{emoji}</Button>
                          })}
                        </ButtonGroup>
                      </div>
                    }}
                    timeFormat={"HH:mm"}
                    isAnimated={true}
                    showAvatarForEveryMessage={true}
                    user={this.state.user}
                    messages={this.state.messages.slice().reverse()}
                    onSend={(messages) => this.onSend(messages)}
                    onPressAvatar={(avatar) => this.onAvatarClicked(avatar)}
                    renderCustomView={(message) => this.renderCustomView(message)}
                  />
                </ScrollbarTag>
                <div className="d-flex" style={{
                  height: 120
                }}>
                  <textarea
                    ref={this.chatInputRef}
                    type={"textarea"} name={"message"} onChange={e => {
                    this.setState({
                      new_message_text: e.target.value
                    })
                  }}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        this.sendNewMessage()
                        e.preventDefault()
                        return false;
                      }
                    }}
                    className={"rounded-0 w-100 border border-left-0 border-right-0"}
                    value={this.state.new_message_text}></textarea>
                  <Button onClick={() => this.sendNewMessage()}
                          className={"p-1 rounded-0"}><Icon.ArrowRightCircle/></Button>
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </ContextLayout.Consumer>
    )
  }

  formatMessageText(text) {
    let _textNew = ""
    let noSpace = 0
    for (let a = 0; a < text.length; a++) {
      if (text[a] !== " ")
        noSpace++
      _textNew += text[a];
      if (noSpace >= 20) {
        noSpace = 0;
        _textNew += " ";
      }
    }
    return _textNew;
  }

  getUserColor(id) {
    const colors = ["primary", "secondary", "info", "dark", "danger", "warning", "light"]
    let encodedI = parseInt((atob(id)).match(/(\d+)/)) % colors.length
    return colors[encodedI]
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.login.userRole
  }
}

export default connect(mapStateToProps)(Chat)
