import React, {Fragment} from "react";
import moment from "moment-timezone";
import TopSponsors from "./TopSponsors";
import {Animated} from "react-animated-css";
import "animate.css/animate.min.css"
import * as classnames from "classnames";
import {fireAuth, fireReal} from "../../../authServices/firebase/firebaseConfig";
import {FormattedHTMLMessage} from "react-intl";
import {frontendBaseUrl} from "../../../App";
import {toast} from "react-toastify";

class FlashNotification extends React.Component {
  refName = "notifications"
  fireQueries = []
  state = {
    showSponsor: true,
    message: false,
    curTime: new Date(),
    items: [
      {id: "test", name: "Test"},
      {id: "test2", name: "Test2"}
    ]
  }


  constructor(props) {
    super(props);
    let self = this
    fireAuth().onAuthStateChanged(function (user) {
      if (user) {
        self.listenNotify("all")
        self.props.reply()
      }
    })
  }

  componentWillMount() {
    setInterval(function () {
      this.setState({
        curTime: new Date()
      })
    }.bind(this), 1000);
  }

  showNotification = (message) => {
    let self = this
    this.setState({
      message: message
    }, () => {
      setTimeout(function () {
        self.setState({
          showSponsor: false
        })
      }, 1000)
    })
  }

  hideNotification = () => {
    this.setState({
      showSponsor: true,
      message: null
    })
  }

  processNotify = (data, target) => {
    let self = this
    let _type = data.type
    switch (_type) {
      case "marquee":
        let duration = data.data.duration
        self.showNotification(data.data.content)
        setTimeout(function () {
          self.hideNotification()
        }, duration * 1000)
        break;
    }
    return ""
  }

  listenNotify = (target) => {
    let self = this

    let Query = fireReal.ref(this.refName + '/' + target)
      .orderByChild('createdAt')
      .startAt(Date.now())
    this.fireQueries.push(Query)
    Query.on('child_added', function (item) {
      let data = item.val()
      data.key = item.key
      self.processNotify(data, target)
    });

    return []
  }


  offListeners = () => {
    this.fireQueries.forEach(function (l) {
      l.off("child_added")
    })
  }

  render() {
    return <div className={"max-width-40 w350px overflow-hidden position-relative"}>
      <Animated
        duration={1000}
        className={classnames("standard-case w-100", {"d-none": !this.state.showSponsor})}
        animationIn="animate__fadeIn" animationOut="animate__fadeOut"
        isVisible={typeof this.state.message !== "string"}
      >
        <div className={"d-flex w-100"}>
          <TopSponsors/>
        </div>
      </Animated>

      <Animated
        className={classnames("flash-notify-case", {"d-none": this.state.showSponsor})}
        animationIn="animate__flash" animationOut="animate__fadeOut"
        isVisible={typeof this.state.message === "string"}>
        <div>
          <div dangerouslySetInnerHTML={{__html: this.state.message || ""}}/>
        </div>
      </Animated>
    </div>
  }
}

export default FlashNotification
