import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "home",
    title: "Giriş",
    type: "item",
    icon: <Icon.Home size={20}/>,
    permissions: ["admin"],
    navLink: "/admin",
  },
  {
    id: "users",
    title: "Kullanıcı Yönetimi",
    type: "item",
    icon: <Icon.Users size={20}/>,
    permissions: ["admin"],
    navLink: "/admin/users",
    children: [
      {
        id: "allUsers",
        title: "Tüm Kullanıcılar",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/users"
      },
      {
        id: "addNewUser",
        title: "Yeni Ekle",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/users/new"
      },
      {
        id: "sendEmail",
        title: "E-posta Gönderimi",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/users/send-email"
      },
      {
        id: "signInAsUser",
        title: "Kullanıcı Hesabına Geçiş",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/users/sign-in-user"
      }
    ]
  },

  {
    id: "chatManagement",
    title: "Anlık İşlemler",
    type: "item",
    icon: <Icon.MessageCircle size={12} />,
    permissions: ["admin"],
    navLink: "/admin/manage-activity",
    children: [
      {
        id: "activityManage",
        title: "Duvar Yönetimi",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/manage-activity"
      },
      // {
      //   id: "chatManage",
      //   title: "Sohbet Yönetimi",
      //   type: "item",
      //   icon: <Icon.Circle size={12}/>,
      //   permissions: ["admin"],
      //   navLink: "/admin/manage-chat"
      // },
      {
        id: "standMeetingStatus",
        title: "Stand Görüşmeleri",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/stand-meeting-status"
      },
      {
        id: "notifications",
        title: "Bildirim Yönetimi",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/notifications"
      }
    ]
  },

  {
    id: "salesManager",
    title: "Satış & Muhasebe",
    type: "item",
    icon: <Icon.CreditCard size={20}/>,
    permissions: ["admin"],
    navLink: "/admin/sales",
    children: [
      {
        id: "allEvents",
        title: "Siparişler",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/sales/list"
      },
      {
        id: "allTickets",
        title: "Biletler",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/tickets/list"
      },
      {
        id: "allStands",
        title: "Standlar",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/stands/list"
      },
      {
        id: "discountCoupons",
        title: "İndirim Kuponları",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/discount-coupons/list"
      }
    ]
  },
  {
    id: "contentManagement",
    title: "İçerik Yönetimi",
    type: "item",
    icon: <Icon.Edit size={20}/>,
    permissions: ["admin"],
    navLink: "/admin/content/list",
    children: [
      {
        id: "allEvents",
        title: "Sık Sorulan Sorular",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/content/faq",
      }
    ]
  },
  {
    id: "eventManagement",
    title: "Etkinlik Yönetimi",
    type: "item",
    icon: <Icon.Command size={20}/>,
    permissions: ["admin"],
    navLink: "/admin/events/list",
    children: [
      {
        id: "allEvents",
        title: "Tüm Etkinlikler",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/events/list"
      },
      {
        id: "addEvent",
        title: "Yeni Etkinlik",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/events/new"
      },
      {
        id: "zoomManagement",
        title: "Toplantı Yönetimi",
        type: "item",
        icon: <Icon.Video size={20}/>,
        permissions: ["admin"],
        navLink: "/admin/meetings"
      },
      {
        id: "zoomAccountManagement",
        title: "Zoom Hesapları Yönetimi",
        type: "item",
        icon: <Icon.Video size={20}/>,
        permissions: ["admin"],
        navLink: "/admin/zoom-accounts"
      },
      {
        id: "sponsorManagement",
        title: "Sponsor Yönetimi",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/sponsor-management"
      },
      {
        id: "bannerManagement",
        title: "Banner Yönetimi",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: ["admin"],
        navLink: "/admin/banner-management"
      }
    ]
  }
]

export default horizontalMenuConfig
