import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import * as Icon from "react-feather"
import {history} from "../../../history"
import authReducers from "../../../redux/reducers/auth";
import {createStore} from "redux";

import defaultAvatar from "../../../assets/img/profile/avatar.png"
import {fireAuth, fireDb} from "../../../authServices/firebase/firebaseConfig";

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
    userAvatar: localStorage.getItem("userAvatar") || defaultAvatar,
    userName: localStorage.getItem('userName') || 'Kullanıcı'
  }

  userLogout() {
    let store = createStore(authReducers);
    store.dispatch({
      type: "LOGOUT",
      payload: {}
    });
    history.push("/pages/login");
  }

  constructor(props) {
    super(props);

    this.userDetailsChangedHandle = this.userDetailsChangedHandle.bind(this);
    fireAuth().onAuthStateChanged(user => {
      if (user) {
        this.loadNavAvatar()
      }
    });
  }


  UserDropdown = () => {
    return (
      <DropdownMenu right>
        <DropdownItem tag="a" onClick={e => {
          history.push('/account/edit');
        }}>
          <Icon.Settings size={14} className="mr-50"/>
          <span className="align-middle">Hesabım</span>
        </DropdownItem>
        <DropdownItem divider/>
        <DropdownItem
          tag="a"
          href="#"
          onClick={e => this.userLogout()}
        >
          <Icon.Power size={14} className="mr-50"/>
          <span className="align-middle">Çıkış Yap</span>
        </DropdownItem>
      </DropdownMenu>
    )
  }

  userDetailsChangedHandle() {
    this.setState({
      userAvatar: localStorage.getItem("userAvatar") || defaultAvatar,
      userName: localStorage.getItem("userName") || 'Kullanıcı',
    });
  }

  componentDidMount() {
    // axios.get("/api/main-search/data").then(({data}) => {
    //   this.setState({suggestions: data.searchResult})
    // })

    window.addEventListener('userDetailsChanged', this.userDetailsChangedHandle);
  }

  componentWillUnmount() {
    window.removeEventListener('userDetailsChanged', this.userDetailsChangedHandle);
  }

  loadNavAvatar = () => {
    fireDb.collection("user").doc(fireAuth().getUid()).get().then(user_detail => {
      let user_detail_data = user_detail.data();
      if (typeof user_detail_data.avatar === "string" && user_detail_data.avatar) {
        localStorage.setItem("userAvatar", user_detail_data.avatar);
        this.userDetailsChangedHandle();
      }
    });
  }


  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <li className={"nav-item d-sm-flex"}>
          <a className={"nav-link dropdown-user-link"} href="/">Siteye Dön <Icon.ExternalLink size={15}  /></a>
        </li>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600 pt-1">
                {this.state.userName}
              </span>
            </div>
            <span data-tour="user">
              <img
                src={this.state.userAvatar}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          {this.UserDropdown()}
        </UncontrolledDropdown>
      </ul>
    )
  }
}


export default NavbarUser
