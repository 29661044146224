import React from "react"
import Router from "./Router"
import "./components/@vuexy/rippleButton/RippleButton"
import "react-perfect-scrollbar/dist/css/styles.css"
import "prismjs/themes/prism-tomorrow.css"
import {IntlProvider} from 'react-intl';
import messages_tr from "./translations/tr/translations.json";
import messages_en from "./translations/en/translation.json";
import {fireAuth, fireDb} from "./authServices/firebase/firebaseConfig";
import {history} from "./history";

export const backendApiBaseUrl = process.env.REACT_APP_API_BASE_URL
export const frontendBaseUrl = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? "http://localhost:3000" : process.env.REACT_APP_FRONT_BASE_URL

window.$ = require('jquery')
window.jQuery = require('jquery')

const getLocalStorageOptions = () => {
  let opts = localStorage.getItem('options');
  if (typeof opts !== "string")
    return {
      name: "Uzakrota",
      home: frontendBaseUrl
    };
  return JSON.parse(localStorage.getItem('options'))
}

const getSiteName = () => {
  return getLocalStorageOptions().name || '';
}

export const firstLetterUppercase = (input) => {
  if(typeof input === "undefined")
    return false;

  input = input.toLocaleLowerCase()
  let arr = input.split(" "), fl = null
  for (let i = 0; i < arr.length; i++) {
    fl = arr[i].charAt(0)
    fl = fl.toLocaleUpperCase()
    arr[i] = fl + arr[i].slice(1);
  }
  return arr.join(" ");
}

export function debounce(fn, time) {
  let timeoutId
  return wrapper

  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      timeoutId = null
      fn(...args)
    }, time)
  }
}

export const isUserLoggedIn = () => {
  return typeof (localStorage.getItem('isLoggedIn') || '') === "string" && localStorage.getItem('isLoggedIn') === "true";
}

const updateSiteName = () => {
  document.title = getSiteName();
}


const messages = {
  'tr': messages_tr,
  'en': messages_en
};

export function getCurrentUserLang() {
  let userLang = ""
  if (typeof localStorage.userLang !== "string") {
    userLang = navigator.language || navigator.userLanguage
    let parseLang = userLang.split("-")
    localStorage.setItem("userLang", parseLang[0])
    return parseLang[0]
  } else {
    return localStorage.getItem("userLang")
  }
}

export function changeUserLang(new_lang) {
  localStorage.setItem("userLang", new_lang);
  if (typeof fireAuth().currentUser === "undefined" || fireAuth().currentUser === null || !fireAuth().currentUser) {
    history.go()
    return false
  }
  fireDb.collection("user").doc(localStorage.getItem("userUid") || fireAuth().currentUser.uid).update({
    language: new_lang
  }).then(() => {
    history.go()
  })
}

export function getLocalOption(slug) {
  let options = localStorage.getItem("options")
  if (options === null)
    return false;

  options = JSON.parse(options)
  return typeof options[slug] !== "undefined" && options[slug] !== null ? options[slug] : false
}

export function init_mini_player(url, stages) {
  window.postMessage({
    event: "mini_player_init",
    data: {
      streamUrl: url,
      stages: stages
    }
  }, "*");
}

export function show_mini_player() {
  window.postMessage({
    event: "mini_player_show",
    data: {}
  }, "*");
}

export function hide_mini_player() {
  window.postMessage({
    event: "mini_player_hide",
    data: {}
  }, "*");
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const activeEventId = getLocalOption("active_event") || process.env.REACT_APP_ACTIVE_EVENT_ID
let onStage = typeof window.location.pathname === "string" && (window.location.pathname).indexOf("/stage") === 0

const App = props => {
  let userLang = getCurrentUserLang();
  history.listen((location, action) => {
    if (typeof location.pathname === "string" && (location.pathname).indexOf("/stage") === 0)
      onStage = true

    if (onStage && typeof location.pathname === "string" && (location.pathname).indexOf("/stage") !== 0) {
      onStage = false
      show_mini_player()
    }

  })

  /**
   fireAuth().onAuthStateChanged(user => {
  });
   */

  updateSiteName()
  return <IntlProvider locale={userLang} messages={messages[userLang]}>
    <Router/>
  </IntlProvider>
}

export default App
