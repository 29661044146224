import React from "react"
import ScrollToTop from "react-scroll-up"
import {Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem} from "reactstrap"
import {ArrowUp} from "react-feather"
import classnames from "classnames"
import {changeUserLang, getCurrentUserLang} from "../../../App";

import trFlag from '../../../assets/img/flags/tr.svg'
import enFlag from '../../../assets/img/flags/en.svg'
import griLogo from '../../../assets/img/logo/gri-only-text.svg'

export const getLangFlag = (_lang) => {
  let img = null
  switch (_lang)
  {
    case 'en':
      img = enFlag;
      break;
    default:
    case 'tr':
      img = trFlag;
      break;
  }
  return (<><img alt={`lang-flag-${img}`} src={img} width={20} />&nbsp;</>)
}

const Footer = props => {
  let footerTypeArr = ["sticky", "static", "hidden"]
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden"
      })}
    >
      <div className={"d-flex"}>
        <p className="mb-0 mr-1 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          COPYRIGHT © {new Date().getFullYear()} - developed by
          <a
            href="https://www.gri.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={griLogo} width={24} alt={"gri"} />
          </a>
          - All rights reserved.
        </span>
        </p>
        <div>

          <UncontrolledDropdown
            tag="li"
            className="dropdown-language list-unstyled nav-item"
          >
            <DropdownToggle
              tag="a"
              data-toggle="dropdown"
              className="nav-link"
            >
              {getLangFlag(getCurrentUserLang())}
              {getCurrentUserLang() !== "en" ? "Türkçe" : "English"}
            </DropdownToggle>
            <DropdownMenu className={"caret-off"}>
              <DropdownItem tag="a" onClick={() => changeUserLang("en")}>
                {getLangFlag("en")}
                English
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => changeUserLang("tr")}>
                {getLangFlag("tr")}
                Türkçe
              </DropdownItem>

            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      {props.hideScrollToTop === false ? (
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15}/>
          </Button>
        </ScrollToTop>
      ) : null}
    </footer>
  )
}

export default Footer
