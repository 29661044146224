import {Button, FormGroup, Label, Modal, ModalBody, ModalHeader, Input, Alert, UncontrolledTooltip} from "reactstrap";
import {BiEnvelope} from "react-icons/bi";
import {FormattedMessage} from "react-intl";
import React from "react";
import {ArrowRight} from "react-feather";
import {backendApiBaseUrl, validateEmail} from "../../../App";
import {fireAuth} from "../../../authServices/firebase/firebaseConfig";
import {FaShareAltSquare} from "react-icons/all";

class InviteFriends extends React.Component {

  constructor(props) {
    super(props);
    this.modalToggle = this.modalToggle.bind(this)
    this.submit = this.submit.bind(this)
  }

  errorRef = React.createRef()
  successRef = React.createRef()


  state = {
    modalOpened: false,
    email: "",
    submitLock: false
  }

  modalToggle = () => {
    this.setState({
      modalOpened: !this.state.modalOpened
    })
  }

  submit = (ev) => {
    ev.preventDefault()

    let email = this.state.email
    let self = this
    if (!validateEmail(email)) {
      this.errorRef.current.style.display = "block";

      this.setState({submitLock: true})
      setTimeout(function () {
        self.errorRef.current.style.display = "none";
        self.setState({submitLock: false})
      }, 5 * 1000)
      return false;
    }

    fireAuth().currentUser.getIdToken().then(token => {
      fetch(`${backendApiBaseUrl}/api/invite`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({
          email: [email],
        })
      }).then(r => r.json()).then(r => {
        this.setState({email: ""})

        this.successRef.current.style.display = "block";
        this.setState({submitLock: true})
        setTimeout(function () {
          self.successRef.current.style.display = "none";
          self.setState({submitLock: false})
        }, 5 * 1000)

      }).catch(r => {
        this.setState({email: ""})
      })
    })
  }

  render() {
    return <>
      <UncontrolledTooltip target={"invite-navbar"}><FormattedMessage
        id={"navbar.inviteModal.button"}/></UncontrolledTooltip>

      <FaShareAltSquare size={24}
                        id={"invite-navbar"}
                        class={"cursor-pointer"}
                        style={{margin:"18px 10px"}}
                        onClick={this.modalToggle}
      />

      <Modal isOpen={this.state.modalOpened} toggle={this.modalToggle}>
        <ModalHeader toggle={this.modalToggle}>
          <FormattedMessage id={"navbar.inviteModal.title"}/>
        </ModalHeader>
        <ModalBody>


          <p className={"text-info"}>
            <FormattedMessage id={"navbar.inviteModal.description"}/>
          </p>
          <form onSubmit={this.submit}>

            <FormGroup>
              <Label><FormattedMessage id={"navbar.inviteModal.emailLabel"}/></Label>
              <Input type={"email"} id={"email"}
                     value={this.state.email}
                     onChange={(e) => {
                       this.setState({email: e.target.value})
                     }}/>
              <div ref={this.errorRef} className={"w-100"} style={{display: "none"}}>
                <Alert className={"mt-1"} color={"danger"}><FormattedMessage
                  id={"navbar.inviteModal.errorText"}/></Alert>
              </div>
              <div ref={this.successRef} className={"w-100"} style={{display: "none"}}>
                <Alert className={"mt-1"} color={"success"}><FormattedMessage
                  id={"navbar.inviteModal.successText"}/></Alert>
              </div>
            </FormGroup>

            <p className={"text-right"}>
              <Button onClick={this.submit} disabled={this.state.submitLock}>
                <FormattedMessage id={"navbar.inviteModal.submitButton"}/> <ArrowRight/>
              </Button>
            </p>

          </form>
        </ModalBody>
      </Modal>
    </>
  }
}

export default InviteFriends
